import React from "react";

import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex flex-col items-center justify-center px-4 py-4 lg:py-8 xl:py-20">
          <img
            alt="Ghost getting abducted by aliens"
            className="block mx-auto"
            src={abductionIllustration}
          />
          <h2 className="block p-3 mx-auto my-8 text-2xl font-bold text-center bg-green-400">
            Looks like this page is a ghost that got abducted by aliens...
          </h2>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
